/* body {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  height: 100%;
  font-weight: 100;
  margin: 0px;
  padding: 0px;
  color: #333333;
  background-color: #ffffff;
} */

html,
body {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  height: 100%;
  font-weight: 100;
  margin: 0px;
  padding: 0px;
  color: #333333;
  background-color: #ffffff;
}

@media screen and (max-width: 769px) {
  .AppWrapper{
    width: 100%;
    height: 100%;
    font-size: 12px;
    min-width: 370px;
    display: flex;
    flex-direction: column;
    /* overflow: scroll; */
    position: relative;
  }
  .service-title h1{
    font-size: 1em;
    background-color: red;
  }
  .contents-wrapper{
    min-width: 370px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
  }
  .left-contents{
    min-height: 300px;
  }
  .right-contents {
    min-height: 500px;
  }
  .footer-component img{
    padding-right: 2px;
  }
}


/* 익스플로러  스크롤바와 화면이 안겹치게 하는 설정 */
@-ms-viewport {
  width: auto;
}